<template>
  <van-form
      ref="form"
      error-message-align="right"
      :readonly="readonly"
      input-align="right"
      @submit="onSubmit"
  >
    <van-cell-group inset :border="false">
      <van-field readonly label="日期" :value="dayjs(resultDate * 1).format('YYYY-MM-DD')"/>
      <van-field
          v-model="detail"
          class="my-textarea"
          required
          label="总结"
          type="textarea"
          maxlength="300"
          show-word-limit
          :rules="[{ required: true, message: '请填写总结' }]"
          :autosize="{ maxHeight: 400, minHeight: 150 }"
      />
    </van-cell-group>

    <div class="btn-group">
      <van-button color="var(--secondMainColor)" native-type="button" @click="goBack">
        返回
      </van-button>
      <van-button
          v-if="!readonly"
          color="var(--mainColor)"
          native-type="submit"
          :loading="submitting"
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import {saveReviewSummary, queryReviewSummary, queryReviewSummaryGetOrCreate} from "@/api/hoss";
import dayjs from "dayjs";
import {CloudSaveStatus} from "@/api/consts";

export default {
  data() {
    return {
      resultDate: +new Date(),
      detail: "",
      readonly: false,
      submitting: false,
      id: "",
    };
  },
  async created() {
    const {id = "", milepostId = "", execDate = ""} = this.$route.query;
    this.milepostId = milepostId;
    try {
      const {value} = await queryReviewSummaryGetOrCreate(id, milepostId, execDate);
      const {resultDate, detail} = value;
      this.resultDate = resultDate;
      this.detail = detail;
      this.readonly = !!value.state;
      this.id = value.id;
      if (this.readonly) {
        this.$emit("changeTitleBarComponent", {
              name: "CloudSaveStatusView",
              params: {
                status: CloudSaveStatus.SUCCESS,
                isEdit: !this.readonly,
                type: "Normal",
                happenedTimeMs: Date.now()
              }
            }
        );
      }
    } catch (e) {
      this.readonly = true;
    }
  },
  methods: {
    dayjs,
    async onSubmit() {
      this.submitting = true;
      console.log("submit");
      try {
        const {resultDate, detail, milepostId, id} = this;
        await saveReviewSummary({resultDate, detail, milepostId, id});
        this.goBack();
      } catch (error) {
        console.log(error);
      }
      this.submitting = false;
    },
    onTitleBarNeedTransferData(data) {
      console.log("onTitleBarNeedTransferData", data);
      if (data === 'changeToEdit') {
        this.readonly = false;
        this.$emit("changeTitleBarComponent", {
              name: "CloudSaveStatusView",
              params: {
                status: CloudSaveStatus.SUCCESS,
                isEdit: !this.readonly,
                type: "Normal",
                happenedTimeMs: Date.now()
              }
            }
        );
      } else if (data === "needSave") {
        this.onSubmit();
      }
    },
    goBack() {
      this.$router.back();
    },
    onConfirm(date) {
      this.resultDate = +date;
      this.showCalendar = false;
    },
  },
};
</script>

<style lang="less" scoped>
.van-form {
  margin-bottom: 54px;

  .van-cell-group {
    margin: 10px;
    border-radius: 10px;
  }

  .btn-group {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;

    .van-button {
      flex: 1 0 50%;
      border-radius: 0;
    }
  }
}
</style>
