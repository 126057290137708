export class Constants {
    static APP_TYPE_TRANSFER = 1;
    static APP_TYPE_STATEMENT = 2;
    static APP_TYPE_ABORT = 3;
}

export class IconType {
    // 1是当前或已经历阶段
    // 2是审核被拒
    // 3是我可以查看审核
    // 4是还没到达;可以操作
    // 5是无法操作
    // 6是审核通过但尚未完成转阶段
    static DONE_OR_CURRENT = "1";
    static REJECTED = "2";
    static ABLE_TO_VIEW_APPROVE = "3";
    static REACHABLE = "4";
    static NOT_ALLOWED = "5";
    static DONE_BUT_NOT_REACHED = "6";
}

export class OperationStatus{
    static OPERATION_DONE = 1;
    static OPERATION_ALLOWED = 2;
    static OPERATION_NOT_ALLOWED = 3;
    static OPERATION_TO_BE_APPROVED = 4;
    static OPERATION_TO_BE_CONFIRMED = 5;
    static OPERATION_NO_AUTH_CHANGE_STAGE = 6;
    static OPERATION_REJECTED = 7;
}


export class Operation {
    static SUBMIT = 0;
    static AGREE = 1;
    static REJECT = 2;
    static UNAPPROVED = 10;
    static UN_REACHED = 11;
    static DATE_CHANGED = 20;
    static REAPPLY = 30;
    static CANCEL = 21;

    static SYSTEM_AUTO_REJECT = 3;

    static getOperationName(operation) {
        switch (operation) {
            case Operation.SUBMIT:
                return "提交";
            case Operation.AGREE:
                return "同意";
            case Operation.REJECT:
                return "拒绝";
            case Operation.UNAPPROVED:
                return "待审核";
            case Operation.UN_REACHED:
                return "未到达审核流程";
            case Operation.SYSTEM_AUTO_REJECT:
                return "系统自动拒绝";
            case Operation.CANCEL:
                return "取消";
            case Operation.DATE_CHANGED:
                return "日期变更";
            case Operation.REAPPLY:
                return "重新申请";
            default:
                return "";
        }
    }
}

export class HossClientDomain {
    static DOMAIN_EXEC_RECORD = 21;
    static DOMAIN_REVIEW_SUMMARY = 22;
    static DOMAIN_FAN_GROUP_REPORT = 23;
}

/**
 * 云端自动保存状态
 * @type {{UPLOADING: number, SUCCESS: number, ERROR: number}}
 */
export const CloudSaveStatus = {
    ERROR: 0,
    SUCCESS: 1,
    UPLOADING: 2,
    NONE: 3,
}

/**
 * handlerTransfer 处理转二阶段状态
 */
export const ExecutionStatus = {
    WAIT: 19,
    DATE_CHANGED: 20,
    CANCELED: 21,
    CONFIRMED: 22,
}
